#history {
  @apply absolute z-10 top-0 left-0 w-full h-full py-4 font-mono text-right overflow-y-scroll;
  @apply bg-white dark:bg-gray-900 dark:text-white;
  @apply transform translate-y-full;
  @apply transition-all default-transition;

  &.visible {
    @apply translate-y-0;
  }

  > .items {
    @apply transform;

    > .item {
      @apply py-2 px-6 cursor-pointer;
      @apply hover:bg-gray-300 dark:hover:bg-gray-600 hover:bg-opacity-30;

      > .calculation {
        @apply text-xs font-light;
      }

      > .result {
        @apply font-semibold text-xl;
      }
    }
  }
}
