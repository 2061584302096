.calculator-container {
  @apply relative min-h-screen flex flex-col justify-between space-y-4 pb-8 px-4;

  > .illustration-top {
    @apply opacity-0 absolute transition-opacity default-transition;
  }

  > .illustration-bottom {
    @apply opacity-0 absolute transition-opacity default-transition;
  }

  > .title {
    @apply z-10 relative text-4xl font-light;
    @apply dark:text-gray-300;
    @apply transition-colors default-transition;

    &:after {
      content: "";
      @apply absolute bottom-0 right-0 -mb-2 w-24 border border-primary;
      @apply transition-all default-transition;
    }

    > span {
      @apply font-bold;
    }
  }

  > .calculator {
    @apply z-20 max-w-xs shadow-2xl dark:shadow-lg overflow-hidden;
    @apply bg-white dark:bg-dark-primary-lighter;
    @apply transition-all default-transition;
    @apply rounded-2xl;

    > .screen {
      @apply relative p-4 min-h-36 flex flex-col justify-center;
      @apply dark:bg-dark-primary bg-primary-light;
      @apply text-black dark:text-gray-300;
      @apply transition-all default-transition;

      > .history-button {
        @apply absolute bottom-0 left-0 p-4 outline-none transform default-transition;

        &.opened {
          @apply rotate-180;
        }

        > svg {
          @apply h-4 w-4 hidden;

          &.visible {
            @apply block;
          }
        }
      }

      > label {
        @apply hidden;
      }

      > p {
        @apply block w-full outline-none;
        @apply bg-transparent;
        @apply text-right font-mono font-semibold;
        @apply transition-[font] default-transition;

        &.calculation {
          @apply text-sm;
        }

        &.result {
          @apply text-5xl;
        }
      }

      &.size-lg {
        > p {
          &.result {
            @apply text-3xl;
          }
        }
      }

      &.size-md {
        > p {
          &.result {
            @apply text-xl;
          }
        }
      }

      &.extanded {
        > p {
          &.calculation {
            @apply text-5xl;
          }

          &.result {
            @apply text-sm;
          }
        }

        &.size-lg {
          > p {
            &.calculation {
              @apply text-3xl;
            }
          }
        }

        &.size-md {
          > p {
            &.calculation {
              @apply text-xl;
            }
          }
        }
      }
    }

    > .keys {
      @apply relative w-full grid grid-cols-4 gap-4 p-4;
      @apply dark:bg-dark-primary-lighter;
      @apply transition-all default-transition;

      > .key {
        @apply h-15 rounded-full;
        @apply text-xl font-semibold;
        @apply text-black dark:text-gray-300;
        @apply transition-all default-transition;

        &.action {
          @apply bg-primary-light text-black dark:bg-dark-primary dark:text-gray-300;
        }

        &.primary {
          @apply bg-primary text-white dark:text-gray-300;
        }

        &.large {
          @apply col-span-2 aspect-auto;
        }

        &:hover, &.pressed {
            @apply bg-gray-100 dark:bg-gray-700;

            &.action {
                @apply bg-primary-lighter dark:bg-dark-primary-darker;
            }

            &.primary {
                @apply bg-primary-dark dark:bg-primary-dark;
            }
        }
      }
    }

    &.scientific {
      @apply max-w-2xl;

      > .keys {
        @apply grid grid-cols-7;

        > .key {
          @apply aspect-auto h-auto min-h-15;
        }
      }
    }
  }

  > .actions {
    @apply hidden lg:block z-10 rounded-2xl items-center overflow-hidden;
    @apply text-black dark:text-gray-300 text-sm;
    @apply bg-primary-light dark:bg-dark-primary;
    @apply transition-colors default-transition;

    > button {
      @apply px-8 py-2;
      @apply transition-colors default-transition;

      &.active {
        @apply bg-primary text-gray-100 font-semibold;
      }
    }
  }

  > .copyright {
    @apply z-10 font-bold text-base opacity-60;
    @apply dark:text-gray-300;
    @apply transition-colors default-transition;

    > span {
      @apply font-light;
    }
  }

  @media only screen and (max-height: 700px) and (orientation: landscape) {
    @apply pt-0 min-h-screen justify-center;

    > .title {
      @apply col-span-3;
    }

    > .title,
    .actions,
    .copyright {
      @apply hidden;
    }

    > .calculator.scientific {
      @apply max-w-none min-h-screen rounded-none my-0;

      > .screen {
        @apply h-auto;
      }

      > .keys {
        @apply gap-2;
        > .key {
          @apply min-h-0 text-sm p-2;
        }
      }
    }
  }
}
