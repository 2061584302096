@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./style/fonts";
@import "./style/global";
@import "./style/navbar";
@import "./style/calculator";
@import "./style/history";

@import "./style/themes/pink";
@import "./style/themes/retro";
@import "./style/themes/glass";
@import "./style/themes/inverted";
