.calculator-container.theme-inverted {
  @apply flex-col-reverse;

  > .title {
    &:after {
      @apply left-0;
    }
  }

  > .calculator {
    @apply transform rotate-180;

    > .screen {
      @apply transform rotate-180;

      > .history-button {
        @apply rotate-180;

        > svg {
          @apply rotate-180;
        }

        &.opened {
          @apply rotate-0;

          > svg {
            @apply rotate-0;
          }
        }
      }
    }

    > .keys {
      > #history {
        > .items {
          @apply rotate-180;
        }
      }
      > .key {
        @apply transform rotate-180;
      }
    }
  }
}
