#navbar {
  @apply z-50 fixed top-0 left-0 w-full p-4 flex items-center justify-between;
  @apply dark:text-gray-300;
  @apply bg-theme-background dark:bg-theme-background-dark;
  @apply transition-all default-transition;

  svg {
    @apply h-6 w-6;
  }

  > .logo {
    @apply font-bold text-xl;
  }

  > .actions {
    @apply flex items-center space-x-4;

    > .theme-select {
      @apply relative h-6 w-6;

      > .items {
        @apply absolute right-0 mt-2 w-36 origin-top-right rounded-lg shadow-2xl overflow-hidden transform opacity-100 scale-100;
        @apply bg-white;
        @apply ring-1 ring-white ring-opacity-5 focus:outline-none;

        > button {
          @apply flex w-full items-center px-4 py-2 space-x-2;
          @apply text-gray-900 text-sm;

          &.active {
            @apply bg-gray-100;
          }

          > svg {
            @apply h-4 w-4;
          }

          > .color-icon {
            @apply h-4 w-4 rounded-full border;
          }
        }
      }
    }

    > .user-info {
      @apply relative h-6 w-6;

      img.avatar {
        @apply rounded-full object-cover;
      }

      > .items {
        @apply absolute right-0 mt-2 origin-top-right rounded-lg py-2 shadow-2xl overflow-hidden transform opacity-100 scale-100;
        @apply bg-white;
        @apply ring-1 ring-white ring-opacity-5 focus:outline-none;

        > .item {
          @apply flex w-full px-4 py-2;
          @apply text-gray-900 text-xs;
          @apply hover:bg-gray-100;

          &.details {
            @apply flex-col whitespace-nowrap;
          }

          &.logout {
            @apply flex-row items-center space-x-2;

            > svg {
              @apply h-4 w-4 opacity-90;
            }
          }

          > .username {
            @apply block text-sm font-semibold opacity-90;
          }

          > .email {
            @apply text-xs block font-light;
          }
        }

        > hr {
          @apply my-1;
        }
      }
    }
  }

  @media only screen and (max-height: 700px) and (orientation: landscape) {
    @apply hidden;
  }
}
