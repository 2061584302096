.calculator-container.theme-retro {
  @apply font-mono;

  > .title {
    &:after {
      @apply border-[#DB6428] border-dashed;
    }
  }

  > .calculator {
    @apply rounded-none;

    > .screen {
      @apply bg-[#BFBFA1] text-black border-8 dark:border-[#1f2636] border-black p-4;
    }

    > .keys {
      > .key {
        @apply rounded-none;
        @apply text-black;
        background-color: #f6f0eb;
        box-shadow: #7d7768 4px 4px 0;
        transition: transform 200ms, box-shadow 200ms;

        &:active,
        &.pressed {
          @apply shadow-none translate-x-[4px] translate-y-[4px] rounded-none;
        }

        &.action {
          @apply text-black;
          background-color: #eaaf72;
        }

        &.primary {
          @apply text-white;
          background-color: #db6428;
        }
      }
    }
  }

  > .actions {
    @apply rounded-none;

    > button {
      &.active {
        background-color: #db6428;
      }
    }
  }
}
