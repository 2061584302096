#navbar {
  backdrop-filter: blur(9.1px);
  -webkit-backdrop-filter: blur(9.1px);
  @apply bg-opacity-40 #{!important};
}

.calculator-container.theme-glass {
  > .illustration-top {
    @apply z-0 absolute left-0 bottom-0 transform -translate-x-1/2 translate-y-1/2 w-screen aspect-square bg-gradient-to-tr from-pink-400 to-primary opacity-25;
    border-radius: 24% 76% 70% 30% / 30% 30% 70% 70%;
  }

  > .illustration-bottom {
    @apply z-0 absolute right-0 top-0 transform translate-x-1/2 -translate-y-1/2 w-screen aspect-square bg-gradient-to-tr from-primary via-indigo-400 to-red-500 opacity-25;
    border-radius: 24% 76% 70% 30% / 30% 30% 70% 70%;
  }

  > .calculator {
    @apply bg-opacity-40;
    backdrop-filter: blur(9.1px);
    -webkit-backdrop-filter: blur(9.1px);

    > .screen {
      @apply bg-opacity-30;
    }
  }
}
