body {
  @apply block font-[Lazzer] min-h-page;
  @apply bg-theme-background dark:bg-theme-background-dark;
  @apply transition-colors default-transition;

  @media only screen and (max-height: 700px) and (orientation: landscape) {
    @apply p-0;
  }
}

.wrapper {
  @apply flex flex-col justify-center items-center pt-page;

  > .wrapper-content {
    @apply w-full;
  }
}

.default-transition {
  @apply duration-300 ease-in-out;
}
