.calculator-container.theme-pink {
  > .title {
    &:after {
      @apply border-pink-500;
    }
  }

  > .calculator {
    > .screen {
      @apply bg-pink-100 text-black;
    }

    > .keys {
      > .key {
        &.action {
          @apply bg-pink-100 text-black hover:bg-opacity-75;
        }

        &.primary {
          @apply bg-pink-500 hover:bg-opacity-75;
        }

        &:hover,
        &.pressed {
          &.action {
            @apply bg-pink-200 dark:bg-pink-200;
          }

          &.primary {
            @apply bg-pink-800 dark:bg-pink-800;
          }
        }
      }
    }
  }

  > .actions {
    > button {
      &.active {
        @apply bg-pink-500;
      }
    }
  }
}
